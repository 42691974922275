/*-----------------------------
    Portfolio details  
--------------------------------*/
.rwt-portfolio-details {
    .content-left {
        .title {
            font-size: 38px;
            margin-bottom: 33px;

            @media #{$sm-layout} {
                font-size: 25px;
                margin-bottom: 20px;
            }
        }

        .single-list-wrapper {
            margin: -5px;

            .single-list {
                margin: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;

                &__labels {
                    display: flex;
                    gap: 6px;
                    max-width: 300px;
                    flex-wrap: wrap;
                    justify-content: end;
                }

                &__label {
                    padding: 4px;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 4px;
                    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
                    display: flex;
                    align-items: center;

                    &>div:first-child {
                        line-height: .7;
                        margin-right: 3px;
                    }

                    &>div:last-child {
                        line-height: 1;
                    }
                }




                &+.single-list {
                    border-top: 1px solid var(--color-border);
                }

                label {
                    font-size: 20px;
                    color: #fff;
                    display: inline-block;
                    padding-right: 16px;
                    margin: 0;
                }

                span {
                    font-size: 18px;
                }
            }
        }
    }

    .content-right {

        border: 1px solid var(--color-border);
        padding: 40px 30px;

        .subtitle {
            font-size: 28px;
            letter-spacing: 0.5px;
            font-weight: 800;
            background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .description {
            p {
                margin-bottom: 10px;
            }
        }
    }

}

.rwt-portfolio-details {
    .alignwide {
        margin-left: -100px;
        margin-right: -100px;
        max-width: 1400px;
        width: auto;

        @media #{$lg-layout} {
            margin-left: 0;
            margin-right: 0;
        }

        @media #{$md-layout} {
            margin-left: 0;
            margin-right: 0;
        }

        @media #{$sm-layout} {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.portfolio-details {

    & * p {
        margin: 0 0 10px !important;
    }

    & * p:last-child {
        margin-bottom: 0;
    }

    &__header {
        margin-bottom: 60px;
        display: flex;
        justify-content: space-between;


        @media #{$lg-layout, $md-layout, $sm-layout} {
            flex-direction: column;
        }

        @media #{$sm-layout} {
            margin-bottom: 32px;
        }

        & a {
            @media #{$lg-layout, $md-layout, $sm-layout} {
                width: max-content;
            }
        }
    }

    &__title {
        font-size: 32px;
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 800;
        line-height: 1.5;
        margin-bottom: 16px;
    }

    &__subtitle {
        font-size: 26px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 16px;
    }

    &__client,
    &__target {
        padding: 40px 30px;
        height: 100%;

        @media #{$md-layout} {
            padding: 30px 20px;
        }

    }

    &__client-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        @media #{$md-layout} {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0px;
        }

        @media #{$xsmall-mobile} {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0px;
        }

        &>div:first-child {
            width: 70%;

            @media #{$md-layout} {
                width: 100%;
                order: 2;
                margin-top: 25px;
            }

            @media #{$xsmall-mobile} {
                width: 100%;
                order: 2;
                margin-top: 25px;
            }
        }

        &>div:last-child {
            width: 30%;
            display: flex;
            justify-content: flex-end;

            @media #{$md-layout} {
                width: 40%;
            }

            @media #{$xsmall-mobile} {
                width: 50%;
            }

            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }


    }

    &__target {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{$large-mobile} {
            padding: 30px 10px 0px 10px;
        }

        @media #{$sm-layout} {
            padding: 32px 10px 0px 10px;
        }

    }

    &__client-title {
        color: var(--color-heading);
        font-weight: 600;
        margin-bottom: 16px;
    }

    &__target-text {
        font-size: 28px;
        color: #fff;
        font-weight: 800;

        @media #{$md-layout} {
            font-size: 22px;
        }

        @media #{$xsmall-mobile} {
            font-size: 24px;
        }



    }

    &__tasks {
        margin: 60px 0px;

        @media #{$sm-layout} {
            margin: 32px 0px;
        }
    }

    &__task {
        display: flex;
        margin: 24px 0px;
        align-items: center;
    }

    &__task-number {
        width: 20%;
        font-size: 85px;
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 800;
        line-height: 1;
        margin-right: 10px;
        display: flex;
        justify-content: flex-end;

        @media #{$sm-layout} {
            width: 15%;
        }
    }

    &__task-text {
        width: 100%;
    }

    &__tasks-block {
        margin-top: 32px;

        @media #{$sm-layout} {
            margin-top: 0px;
        }
    }

    &__design,
    &__development {
        margin-top: 48px;

        @media #{$large-mobile} {
            margin-top: 28px;
        }
    }

    &__development-block {
        margin-top: 48px;

        @media #{$large-mobile} {
            margin-top: 28px;
        }
    }

    &__tabs {
        margin: 40px 0px 0px 0px;

        & .react-tabs__tab-panel {
            overflow: auto !important;

            @media #{$sm-layout} {
                overflow: auto !important;
            }

        }

        .react-tabs__tab {
            @media #{$md-layout} {
                padding: 12px 14px !important;
            }

            @media #{$sm-layout} {
                padding: 12px 14px !important;
            }
        }

        .rn-tab-button {
            width: max-content !important;

            @media #{$large-mobile} {
                width: max-content !important;
            }
        }

        .tab-button {
            width: 100% !important;
            flex-wrap: nowrap !important;
            overflow: auto !important;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: transparent;
                margin-top: 10px;
            }

            &::-webkit-scrollbar {
                height: 8px;
                background-color: var(--color-blackest);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
            }

            @media #{$large-mobile} {
                width: 100% !important;
                flex-wrap: nowrap !important;
                overflow: auto !important;
            }
        }

        @media #{$large-mobile} {
            margin: 0px 0px 0px 0px;
        }


    }

    &_tabs-mobile {
        & .portfolio-details__tab-img {
            width: 100%;

            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &__tab {
        padding: 20px;
        width: 100%;

        @media #{$large-mobile} {
            padding: 0px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 10px;
        }
    }

    &__techology {
        @media #{$md-layout} {
            margin-top: 28px;
        }

        @media #{$sm-layout} {
            margin-top: 28px;
        }
    }



    &__techology-item {
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
        color: #fff;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0px;
        border-radius: 6px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        &>div:first-child {
            line-height: 1;
        }

        &>div:last-child {
            font-size: 10px;
            margin-top: 4px;
        }
    }

    &__cms {
        @media #{$md-layout} {
            margin-top: 28px;
        }

        @media #{$sm-layout} {
            margin-top: 28px;
        }
    }


    &__cms-item {
        display: flex;
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
        color: #fff;
        padding: 14px;
        font-size: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;

        @media #{$large-mobile} {
            padding: 8px;
            font-size: 32px;
        }

        &>div:first-child {
            margin-right: 10px;
            line-height: 1;
        }

        &>div:last-child {
            font-size: 24px;
            font-weight: 800;
            line-height: 1;

            @media #{$large-mobile} {
                font-size: 20px;
            }
        }
    }

    &_tab-3 {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        @media #{$sm-layout} {
            flex-wrap: nowrap;
            width: max-content;
            overflow: auto;
        }

        @media #{$large-mobile} {
            padding: 20px !important;
        }

        & .portfolio-details__tab-img {

            @media #{$small-mobile} {
                width: 100%;
                max-width: 285px;
            }

        }


    }

    &__tab-img.en {

        &::before {
            content: "Click to scroll";
        }

    }

    &__tab-img {

        width: calc(100% / 3 - 16px);
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        @media #{$md-layout} {
            width: calc(100% / 2 - 16px);
        }

        &::before {
            content: "Кликните для скролла";
            display: block;
            position: absolute;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15%, rgba(15, 15, 17, 0.5607843137) 45%, rgba(15, 15, 17, 0.831372549) 75%, #0f0f11ed 100%) 95%/200% 100%;
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 24px;
            cursor: pointer;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
        }

        &>div {
            overflow: hidden;
            height: 600px;
            border-radius: 10px;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
            }

            & img {
                border-radius: 0px;
            }
        }

        &.active {
            &>div {
                overflow: auto;
            }

            &::before {
                content: none
            }
        }

    }

    &__tab-img-pc.en {

        &::before {
            content: "Click to scroll";
        }

    }


    &__tab-img-pc {

        position: relative;
        padding: 0;

        &>div {
            overflow: hidden;
            height: 600px;
            border-radius: 10px;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
            }
        }




        &::before {
            content: "Кликните для скролла";
            display: block;
            position: absolute;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15%, rgba(15, 15, 17, 0.5607843137) 45%, rgba(15, 15, 17, 0.831372549) 75%, #0f0f11ed 100%) 95%/200% 100%;
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 24px;
            cursor: pointer;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
        }

        &.active {
            &>div {
                overflow: auto;
            }

            &::before {
                content: none
            }
        }
    }



}