/*--------------------------
    Copyright Area  
---------------------------*/
.rn-back-top {
    position: fixed;
    bottom: 50px;
    left: 50px;
    cursor: pointer;
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 46px;
    border-radius: var(--radius);
    text-align: center;
    z-index: 999 !important;
    background-color: var(--color-blackest);

    @media #{$sm-layout} {
        bottom: 80px;
        left: 20px;
    }

    svg {
        color: #fff;
        width: 27px;
        height: 27px;
        font-size: 14px !important;
    }
}

// Copyright Style One 
.copyright-style-one {
    background: var(--color-darkest);
    padding: 20px 0;
    border-top: 1px solid var(--color-border);

    @media #{$sm-layout} {
        padding: 20px 0 80px 0px;
    }

    .copyright-text {
        margin-bottom: 0;
        font-size: 14px;
        display: flex;
        align-items: center;

        @media #{$large-mobile} {
            width: 265px;
        }

        &>div:first-child {
            width: 32px;
            height: 32px;
            margin-right: 12px;

            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &>div:last-child {
            text-align: left;
            flex: 1;
        }
    }

    .ft-menu {
        padding: 0;
        margin: 0 -10px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media #{$sm-layout} {
            justify-content: center;
        }

        li {
            margin: 0;
            padding: 0 10px;
            position: relative;

            &+li {
                &::after {
                    position: absolute;
                    content: "";
                    height: 14px;
                    width: 1px;
                    background: var(--color-border);
                    left: -1px;
                    top: 50%;
                    transform: translateY(-50%);

                    @media #{$slaptop-device} {
                        content: none;
                    }
                }
            }

            a {
                font-size: 14px;
            }
        }
    }

    &.variation-two {
        background: var(--color-blackest);
    }

    &.no-border {
        border-top: 0;
    }
}