/*==========================
    Contact Address 
============================*/

.contact-form-1 {
    position: relative;
    padding: 32px;
    border: 1px solid var(--color-border);

    @media #{$lg-layout} {
        padding: 30px 20px;
    }

    @media #{$md-layout} {
        padding: 30px 20px;
    }

    @media #{$sm-layout} {
        padding: 30px 20px;
    }

    &>div:nth-child(7) {
        font-size: 14px;
    }

    .form-group {
        input {
            height: 60px;
            padding: 0 20px;
        }

        textarea {
            padding-top: 20px;
            padding-left: 20px;
            min-height: 140px;
        }

        button {
            width: 100%;
        }

    }

    .file-upload {
        margin-bottom: 0;

        & section,
        & article {
            margin-bottom: 0;
        }

        input {
            height: 100%;
            padding: 0 20px;
            cursor: pointer;
        }

        &__subtitle {
            font-size: 12px;
        }

        &__preview {
            &>div>span {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                height: 1.2em;
                white-space: nowrap;
            }
        }

        p {
            margin-bottom: 5px;
            color: #fff;
        }
    }

    &>.form-group {

        &:last-child,
        &:nth-child(8) {
            margin-bottom: 0px;
        }
    }

}

.fl-contacts {
    &__block {
        margin-bottom: 48px;
        color: #fff;

        &>div:first-child {
            font-size: 20px;
            margin-bottom: 12px;
            line-height: 1;
            font-weight: 400;
        }

        &>a,
        &>div {
            font-size: 28px;
            color: #fff;
            font-weight: 700;
        }
    }
}


.contact-address-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/*-------------------
    Address Area  
--------------------*/
.rn-address {
    margin-top: 30px;
    padding: 32px;
    border-radius: var(--radius);
    padding-top: 40px;
    background: var(--color-blackest);
    padding-bottom: 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
    border: 1px solid var(--color-border);

    @media #{$lg-layout} {
        padding: 30px 20px;
    }

    @media #{$md-layout} {
        padding: 30px 20px;
    }

    @media #{$sm-layout} {
        padding: 30px 20px;
    }

    .icon {
        color: var(--color-primary);
        border-radius: 100%;
        font-size: 40px;
        display: inline-block;
        margin-bottom: 17px;
        line-height: 6px;
        width: 52px;
        height: 52px;
    }

    .inner {
        h4 {
            &.title {
                font-size: 18px;
                font-weight: 700;
            }
        }

        p {
            font-size: 18px;
            margin-bottom: 0;

            @media #{$lg-layout} {
                font-size: 16px;
            }

            @media #{$md-layout} {
                font-size: 16px;
            }

            @media #{$sm-layout} {
                font-size: 16px;
            }

            br {

                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }

            a {
                color: var(--color-body);
                @extend %transition;

                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }

    &:hover {
        transform: translateY(-5px)
    }
}


.google-map-style-1 {
    width: 100%;
    height: 550px;
    border-radius: 10px;

    div {
        border-radius: 10px;
    }
}

.success-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-blackest);
    border-radius: 10px;

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;

        @media #{$sm-layout} {
            padding: 0px 12px;
        }
    }

    &__img {
        max-width: 450px;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-size: 28px;
        color: #fff;
        font-weight: 800;
    }

    &__subtitle {
        text-align: center;
    }

    & p {
        margin-bottom: 0;
    }
}

// Messengers mobile

.messenger-panel {
    display: none;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, .15);
    z-index: 9999;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 60px;

    @media #{$sm-layout} {
        display: flex;
    }

    & div {
        position: relative;
        background-size: auto 50%;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        height: 100%;
        width: calc(100vw/3);
        background-color: var(--color-dark) !important;

        & a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__callback {
        background-image: url(../../images/icons/call.png);
    }

    &__callback.active {
        background-image: url(../../images/icons/close_1.png);
    }

    &__whatsapp {
        background-image: url(../../images/icons/whatsapp.png);
    }

    &__telegramm {
        background-image: url(../../images/icons/tg.png);
    }
}

.form-gudok {
    max-width: 550px;
}

@media #{$sm-layout} {
    html body #cbw-buttonContainer {
        bottom: -100% !important;
        z-index: -1 !important;
        opacity: 0 !important;
    }
}

html body #cbw-formButton {
    top: 210px !important;
}

html body #cbw-buttonContainer {
    bottom: 20px !important;
    right: 16px !important;
}